import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/page';
import { DefaultHeader } from 'components/Header';
import { innerHTML } from 'lib/homePageHelper';
import 'styles/tailwind.scss';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import { getCookie } from 'lib/cookie';
import { authenticate } from 'store/actions/user';
import { CircleCheckFilledIcon } from 'public/images/icons/index';

import HomePageTopQuoteBanner from 'components/HomePageQuoteBanner/HomePageTopQuoteBanner';
import IconTextBlock from 'components/IconTextBlock/IconTextBlock';
import NavButtonWithIconList from 'components/NavButtonWithIcon/NavButtonWithIconList';
import HomePageIntroSectionTitle from 'components/HomePageIntroSectionTitle/HomePageIntroSectionTitle';
import { HomePageParagraph } from 'components/HomePageParagraph';
import HandyTools from 'components/HandyTools/HandyTools';
import HowItWorks from 'components/HowItWorks/HowItWorks';
import { HomePageInnerQuoteBanner } from 'components/HomePageQuoteBanner';
import { ParagraphNavCardList } from 'components/ParagraphNavCard';
import CommonFAQs from 'components/CommonFAQs/CommonFAQs';
import { FooterV3, menus } from 'components/FooterV3/index';
import { CustomerRateList, rateList } from 'components/CustomerRateList/index';
import {
  homePageQuoteTopBannerData,
  homePageQuoteInnerBannerData,
  pointList,
  whySellYourCarList,
  ourServiceList,
  sellYourUsedCarForTheBestData,
  ourCustomersLoveUsData,
  changingTheWayBusinessData,
  questionData,
} from '../constants/homePageV3DataSource';

const MainPage = props => {
  const { pageTitle, pageDescription } = props;
  const scriptInjection = `<script type="application/ld+json">
  {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Car.co.uk",
    "legalName": "New Reg Limited",

    "description": "The home of everything of automotive. Car.co.uk provides a scrap my car service, car auctions, car finance, vehicle inspections, car warranties and car insurance to the UK. Car also provides the UK motorists with free tools and services for car valuation, check MOT history and vehicle reminders personalised to them. ",
    "logo": "https://stage-drupal.car.co.uk/s3fs-public/email/Car_co_uk_logo_white_small.png",
    "url": "https://www.car.co.uk",
    "sameAs": ["https://twitter.com/car","https://www.linkedin.com/company/car","https://www.facebook.com/car.co.uk","https://www.youtube.com/channel/UCtv8BcIVc95BgqMzohzPrQQ","https://www.instagram.com/car.co.uk"],
	  "address": {
      "@type": "PostalAddress",
      "streetAddress": "117 Liverpool Road",
      "addressLocality": "Longton",
      "postalCode": "PR4 5AA",
      "addressCountry": "United Kingdom"
    },
    "contactPoint" : [
      {
        "@type" : "ContactPoint",
        "contactType" : "customer service",
        "email": "help@car.co.uk",
        "url": "https://www.car.co.uk"
      }
    ],
    "brand": {
        "@type": "Brand",
        "name": "Car"
    },
    "hasOfferCatalog": {
    "@type": "OfferCatalog",
    "name": "Automotive Services",
    "itemListElement": [
	   {
     "@type" : "ListItem",
     "name" : "Scrap My Car",
     "Description" : "Instant online quotes & free collection. The leading way to sell and recycle your old vehicle.",
     "url" : "https://www.car.co.uk/scrap-my-car"
    },
    {
     "@type" : "ListItem",
     "name" : "Car Warranties",
     "Description" : "A Car warranty is an extended warranty that offers protection from the costs of parts and labour on your used vehicle.",
     "url" : "https://www.car.co.uk/car-warranties"
    },
    {
     "@type" : "ListItem",
     "name" : "Car Finance",
     "Description" : "If you are looking to purchase a new car then Car.co.uk can help by finding you car finance through their leading partners. Get behind the wheel even faster with a great apllicaiton process!",
     "url" : "https://www.car.co.uk/car-finance"
    },
    {
     "@type" : "ListItem",
     "name" : "Car Insurance",
     "Description" : "Car insurance comparison comparing over 50 insurance providers in one applicaiton process. Compare results online to help with your purchase decision.",
     "url" : "https://www.car.co.uk/motor-insurance"
    },
    {
     "@type" : "ListItem",
     "name" : "Car Valuation",
     "Description" : "Discover the true value of your vehicle or desired car for FREE. See prices under different scenarios and discover the truth about how a car is priced.",
     "url" : "https://www.car.co.uk/car-valuation"
    },
    {
     "@type" : "ListItem",
     "name" : "MOT History",
     "Description" : "A FREE service that allows you to check the MOT on a used vehicle. Obtain the results from past tests and validate the history of a used car!",
     "url" : "https://www.car.co.uk/mot-history-check"
    },
    {
     "@type" : "ListItem",
     "name" : "Vehicle Inspections",
     "Description" : "Buying a used car? Expert vehicle inspections from a company that you can trust. Book your car inspection today and make sure you are buying what you paid for. Pre-purchase vehicle inspections allow you to buy with confidence!",
     "url" : "https://www.car.co.uk/vehicle-inspections"
    }
    ]
    }
    }

    </script>`;
  const containerClass = 'tw-container tw-px-6 tw-mx-auto';
  const commonYSpaceClass = 'tw-pt-6 tw-pb-10 md:tw-pt-12 md:tw-pb-16';

  return (
    <Page title={pageTitle} description={pageDescription} type="article">
      <div className="homePageV2__container tailwind">
        <div className="homePage">
          <DefaultHeader />

          <HomePageTopQuoteBanner bannerData={homePageQuoteTopBannerData} />

          <div className={`${containerClass} ${commonYSpaceClass} sm:tw-px-0 tw-our-services`}>
            <HomePageIntroSectionTitle
              title={{
                mainContent: 'Our services',
                subContent: 'Chose from a range of motor industry services and car ownership guides.',
              }}
            />
            <NavButtonWithIconList navItems={ourServiceList} buttonStyle="short" />
          </div>

          <HomePageParagraph paragraphData={sellYourUsedCarForTheBestData} />

          <section className="tw-w-full tw-bg-grey-light">
            <div
              className={`${containerClass} ${commonYSpaceClass} tw-points sm:tw-px-0 slg:tw-py-16 tw-border-t tw-border-primary-50`}
            >
              <IconTextBlock type="noDescription" list={pointList} icon={CircleCheckFilledIcon}></IconTextBlock>
            </div>
          </section>

          <div className="tw-text-white tw-bg-primary-500">
            <HowItWorks
              classNames={`${containerClass} ${commonYSpaceClass}`}
              title={{
                mainContent: 'How it works',
                subContent:
                  'Selling your car with Car.co.uk  couldn’t be easier. We specialise in buying lower value cars and finding the best buyer for them. Be that a retailer, an auction house, a salvage agent or a scrap yard…we take the hassle away and handle the whole process.',
              }}
            />
          </div>

          <HomePageParagraph paragraphData={ourCustomersLoveUsData} />

          <div className="handy-tools tw-bg-grey-light">
            <div className={`${containerClass} ${commonYSpaceClass}`}>
              <HomePageIntroSectionTitle
                title={{
                  mainContent: 'Important decisions made easier with our handy tools',
                }}
              />
              <HandyTools />
            </div>
          </div>

          <div className={`tw-rate-list ${commonYSpaceClass}`}>
            <HomePageIntroSectionTitle
              title={{
                mainContent: 'Our customers love us!',
              }}
              hideMargin
            />
            <CustomerRateList rateList={rateList} />
          </div>

          <HomePageInnerQuoteBanner bannerData={homePageQuoteInnerBannerData} />

          <div className={`${containerClass} ${commonYSpaceClass} why-sell-car sm:tw-px-0`}>
            <HomePageIntroSectionTitle
              title={{
                mainContent: 'Why sell your old car with Car.co.uk',
                subContent:
                  'From instant online quotes to free car collection from any UK location, we make sure every step is quick, easy, and friendly.',
              }}
              limitWidth={false}
            />
            <IconTextBlock type="withDescription" list={whySellYourCarList} icon={CircleCheckFilledIcon} />
          </div>

          <HomePageParagraph paragraphData={changingTheWayBusinessData} />

          <div className={`${containerClass} ${commonYSpaceClass} sm:tw-px-0 tw-px-12 tw-paragraph-nav-list`}>
            <HomePageIntroSectionTitle
              title={{
                mainContent: 'Our latest news, reviews & guides',
                subContent:
                  'Looking for news, information, and guides relating to motoring? We’ve put it all in one place for you to browse.',
              }}
              limitWidth={false}
            />

            <ParagraphNavCardList cardStyle="withLabel" queryMedia />
          </div>

          <div className={`common-questions tw-bg-grey-light ${commonYSpaceClass}`}>
            <div className={`${containerClass}`}>
              {/* <HomePageIntroSectionTitle
                title={{
                  mainContent: 'Common questions around selling your car and sub text',
                  subContent: 'Lorem ipsum dolor sit amet consectetur. Id eget orci auctor viverra set.',
                }}
              /> */}
              <a href="/scrap-my-car/faqs">
                <h2>
                  <strong>Common questions about selling your car with Car.co.uk</strong>
                </h2>
              </a>

              <CommonFAQs questionData={questionData} />
            </div>
          </div>
        </div>
        <div {...innerHTML(scriptInjection)}></div>
        <FooterV3 {...menus} />
      </div>
    </Page>
  );
};

MainPage.propTypes = {
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  quoteTopBannerData: PropTypes.object,
  quoteInnerBannerData: PropTypes.object,
  sellYourUsedCarForTheBestData: PropTypes.object,
  ourCustomersLoveUsData: PropTypes.object,
  changingTheWayBusinessData: PropTypes.object,
  paragraphNavList: PropTypes.object,
  paragraphNavListWithLabel: PropTypes.object,
  ourServiceList: PropTypes.object,
  pointList: PropTypes.arrayOf(
    PropTypes.shape({
      subTitle: PropTypes.string,
    }),
  ),
  whySellYourCarList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  whatIsADamagedCarList: PropTypes.object,
};

MainPage.getInitialProps = ({ store, req }) => {
  // authentication for server-side
  if (req?.headers?.cookie) {
    const token = getCookie('access_token', req);
    store.dispatch(authenticate({ token }));
  }

  // TODO async get some data from Drupal maybe
  const socialValueUrl = `https://www.car.co.uk/social-value`;
  return {
    pageTitle: `The Home of Everything Automotive - Car.co.uk`,
    pageDescription: `Find the very best deals for car finance, insurance, warranties, and scrap cars - along with news, car valuation, check MOT history, reviews, and helpful guides!`,
  };
};

export default MainPage;
