import React, { useEffect, useState } from 'react';
import { handyToolsCenter, handyToolsLeft, handyToolsRight } from 'public/images/icons/index';
import { mobileAndTabletCheck } from 'helpers/mobileAndTabletCheck';
const HandyTools = () => {
  const tools = [
    {
      text: `Free car valuation`,
      description: 'Get an up to date valuation for your old car in seconds.',
      link: '/car-valuation',
      btnText: 'Get a valuation',
      icon: handyToolsLeft,
    },
    {
      text: `MOT history check`,
      description: 'See the history of any vehicle and understand its MOT history.',
      link: '/check-mot-history',
      btnText: 'Check your MOT',
      icon: handyToolsCenter,
    },
    {
      text: `Car finance calculator`,
      description: 'Find out how much you can borrow, and what it will cost to repay.',
      link: '/car-finance/car-finance-calculator',
      btnText: 'Calculate now',
      icon: handyToolsRight,
    },
  ];
  const [scrollbarClassName, setScrollbarClassName] = useState('');
  useEffect(() => {
    const isMobileOrTablet = mobileAndTabletCheck();
    if (isMobileOrTablet) {
      setScrollbarClassName('tw-scrollbar-hidden');
    } else {
      setScrollbarClassName('tw-scrollbar-mini');
    }
  }, []);
  return (
    <div
      className={`handy-tools-container tw-flex tw-gap-x-8 tw-overflow-x-auto ${scrollbarClassName}`}
      style={{ 'scroll-snap-type': 'x mandatory' }}
    >
      {tools.map((item, index) => {
        let iconClassName = 'tw-absolute';
        switch (index) {
          case 0:
            iconClassName = `${iconClassName} tw-h-[4.5rem] xs:tw-h-24 sm:tw-h-20 slg:tw-h-16 sxl:tw-h-20 tw-top-0 tw--left-12 tw-transform tw-translate-x-[70%]
             xs:tw-translate-x-1/2  sm:tw-translate-x-[70%] slg:tw-translate-x-[90%] sxl:tw-translate-x-[70%]`;
            break;
          case 1:
            iconClassName = `${iconClassName} tw-h-16 slg:tw-h-12 sxl:tw-h-16 tw--bottom-0 tw--left-12 tw-transform tw-translate-x-1/3 slg:tw-translate-x-1/2 sxl:tw-translate-x-1/3`;
            break;
          case 2:
            iconClassName = `${iconClassName} tw-h-10 slg:tw-h-8 sxl:tw-h-10 tw--bottom-0 tw--right-12 tw-transform tw--translate-x-1/2 slg:tw-translate-x-[-65%] sxl:tw--translate-x-1/2 `;
            break;

          default:
            break;
        }
        return (
          <div
            className="tool-item tw-relative tw-rounded-3xl tw-px-4 tw-pb-6 xxs:tw-pb-8 tw-pt-12 xxs:tw-pt-8  tw-bg-primary-500 tw-text-white
            xl:tw-w-1/3 slg:tw-min-w-[30%] md:tw-min-w-[469px] tw-min-w-[83%] tw-overflow-hidden"
            key={`${item.text}`}
            style={{ 'scroll-snap-align': 'start' }}
          >
            <div className="tw-mb-8">
              <div className="tw-text-center tw-font-bold tw-mb-5 tw-text-xl md:tw-text-s2xl sxl:tw-text-s3xl">
                {item.text}
              </div>
              <div className="md:tw-text-lg tw-text-sm tw-text-center tw-line-clamp-2 overflow-ellipsis">
                {item.description}
              </div>
            </div>
            <a href={item.link}>
              <button
                type="button"
                className="tw-rounded-xl xs:tw-py-4 tw-py-2 xs:tw-px-8 tw-px-4 tw-mx-auto tw-block
                tw-bg-green-base hover:tw-bg-white tw-text-sm sm:tw-text-xl
                "
              >
                {item.btnText}
              </button>
            </a>
            <img src={item.icon} alt="tool-item-bg" className={iconClassName} />
          </div>
        );
      })}
    </div>
  );
};

export default HandyTools;
